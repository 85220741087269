<script>
import {handRankKr} from "@/utils/translations";

export default {
  name: "HandRanking",
  props: {
    handType: {
      type: String,
      required: false,
      default: 'table', // table|player
    },
    handRanking: {
      type: String,
      required: true,
    }
  },
  computed: {
    handRankingKor() {
      const handRanking = this.handRanking.match(/[a-z]/gi).join('').toLowerCase();

      return handRankKr(handRanking);
    }
  }
}
</script>

<template>
  <transition appear name="slide-down" mode="out-in">
    <div :class="{
    table_hand_rank: handType === 'table',
    area_hand_rank: handType === 'player'
  }">
      {{ handRankingKor }}
    </div>
  </transition>
</template>

<style scoped lang="scss">
.slide-down-enter-active, .slide-down-leave-active {
  transition: .4s ease-in-out;
}

.slide-down-enter, .slide-down-appear, .slide-down-leave-to {
  position: absolute;
  opacity: 0;
  transform-origin: center center;
  transform: translateY(-100%);
}
</style>